import React from 'react'
import { Box, BoxProps, Graphic, Text, Stack, formatCurrency, Image } from '@thirstycamel/ui'
import imageProxy from '../../utils/imageProxy'

export type ProductBadgeTypes =
  | 'save'
  | 'member'
  | 'multibuy'
  | 'new'
  | 'bundle'
  | 'image'
  | 'everyday'

enum Shapes {
  SHIELD,
  CIRCLE,
}

interface ProductBadgeOptions {
  shape: Shapes
  size?: string
  bg?: string
  line1?: string
  line2?: string
  line1FontSize?: string
  line2FontSize?: string
}

const types: { [key in ProductBadgeTypes]: (...T) => ProductBadgeOptions } = {
  save: ({ price }) => ({
    shape: Shapes.CIRCLE,
    bg: 'black',
    size: '52px',
    line1: 'Save',
    line2: price >= 200 ? formatCurrency(price / 100, { truncateZeros: true }) : '',
    line1FontSize: price > 200 ? 'xs' : 'md',
    line2FontSize: (price / 100) % 1 === 0 ? 'lg' : 'md',
  }),
  member: () => ({
    shape: Shapes.SHIELD,
    bg: 'pink.500',
    line1: 'Member',
    line2: 'Price',
    line1FontSize: 'xs',
    line2FontSize: 'md',
  }),
  multibuy: ({ quantity, price }) => ({
    shape: Shapes.SHIELD,
    bg: 'pink.500',
    line1: `${quantity} for`,
    line2: formatCurrency(price / 100, { truncateZeros: true }),
    line2FontSize: (price / 100) % 1 === 0 ? 'lg' : 'md',
  }),
  new: () => ({
    shape: Shapes.CIRCLE,
    bg: 'green.500',
    size: '52px',
    line2: 'New',
  }),
  bundle: () => ({
    shape: Shapes.CIRCLE,
    bg: 'blue.500',
    size: '52px',
    line1: 'BUNDLE',
    line1FontSize: 'xs',
  }),
  everyday: () => ({
    shape: Shapes.CIRCLE,
    bg: '#F2B742',
    size: '52px',
    // line1: 'EVERYDAY',
    // line2: 'VALUE',
    line1FontSize: 'xs',
  }),
  image: () => ({
    shape: Shapes.CIRCLE,
    size: '52px',
  }),
}

export interface ProductBadgeProps extends BoxProps {
  type: ProductBadgeTypes
  price?: number
  quantity?: number
  isBundle?: boolean
  src?: string
}

export const ProductBadge = ({
  type,
  price,
  quantity,
  isBundle,
  src,
  ...restProps
}: ProductBadgeProps) => {
  const options = types[type]({ price, quantity })

  if (type == 'everyday') {
    src = 'https://storage.googleapis.com/existing-tc-imagery/everyday-value.webp'
  }
  const {
    shape,
    size = '60px',
    bg = 'pink.500',
    line1,
    line2,
    line1FontSize = 'sm',
    line2FontSize = 'lg',
  } = options

  if (src) {
    return (
      <Box position="absolute" top={2} left={2} color="white" userSelect="none" {...restProps}>
        <Box overflow="hidden">
          <Image
            src={type != 'everyday' ? imageProxy(src, { height: 256, width: 256 }) : src}
            height={['56px']}
            width={['56px']}
            objectFit="contain"
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box position="absolute" top={2} left={2} color="white" userSelect="none" {...restProps}>
      {shape === Shapes.SHIELD ? (
        <Graphic name="shield" color={bg} size={size} />
      ) : shape === Shapes.CIRCLE ? (
        <Box size={size} borderRadius="50%" bg={bg} />
      ) : null}

      <Stack
        position="absolute"
        top="50%"
        left="49%"
        transform="translate(-50%, -50%) rotate(-10deg)"
        textTransform="uppercase"
        spacing={-2}
      >
        {line1 && (
          <Text fontSize={line1FontSize} fontFamily="heading">
            {line1}
          </Text>
        )}

        {line2 && (
          <Text fontSize={line2FontSize} fontFamily="heading">
            {line2}
          </Text>
        )}
      </Stack>
    </Box>
  )
}

export default ProductBadge
